import React from "react";
import PageWrapper from "../components/PageWrapper";
import Content from "../sections/landing1/CareersBanner";
import "./custom-header-bg.css"

const Careers = () => {
    return (
        <>
            <PageWrapper
                headerConfig={{
                    bgClass: "dynamic-sticky-bg",
                }}>
                <section id="careers" className="custom-header-background pt-24 pt-lg-32 pb-7 pb-lg-16">
                    <div className="container">
                        {/* <!-- Section Title --> */}
                        <div className="row justify-content-center mb-lg-8 mb-8">
                            <div className="col-xxl-6 col-xl-7 col-lg-8 col-md-10 text-center">
                                <h2 className="mb-4 mb-lg-4 text-white font-size-10">
                                    We are Hiring
                                </h2>
                            </div>
                        </div>
                    </div>
                </section>
                <Content />
                <section id="careers" className="pt-12 pt-lg-16 pb-7 pb-lg-25">
                    <div className="container">
                        {/* <!-- Section Title --> */}
                        <div className="row justify-content-center mb-lg-8 mb-8">
                            <div className="col-xxl-6 col-xl-7 col-lg-8 col-md-10 text-center">
                                <h3 className="mb-4 mb-lg-4 text-black-2">
                                    Open Positions
                                </h3>
                            </div>
                        </div>
                        <div className="pt-12">
                            <h5>CEO</h5>

                            <p className="font-size-4">We are looking for a professional in the Technology driven R&D Services, innovation and invention realization industry with deep CEO/C- Level operational experience and expertise, to start, scale and establish a global business.</p>
                            <p className="font-size-4">This position will be remote, with some traveling required until the steady state and scale of the business is established and then may require relocation depending on the circumstances and ultimate org chart position of the senior leadership team.</p>
                            <p className="font-size-4">E-CTO.com vison is to build a network of global research institutions, acclaimed University laboratories, PhD and post-doctoral research professionals in advanced and niche domains, select software houses and Silicon Valley tech startups.   E-CTO network will synergize and deliver end to end solutions for large corporations and daring entrepreneurs with dreams to build iconic companies with complete techpower to accelerate their ideas to markets.</p>
                            <p className="font-size-4">E-CTO.com is in advanced discussions with leading venture funds and the CEO is expected to be a key partner in leading the investments and executing the vision. The ideal candidate would have aspirations and expectations for “unicorn” type growth.</p>

                            <p className="font-size-4">
                                <h6>Qualifications</h6>
                                <ul>
                                    <li>Must have deep CEO and/or C-Level operational experience with full P&L responsibility, specifically in the tech-enabled services industry (10+ years)</li>
                                    <li>Must have history of full P&L responsibility of at least $100MM</li>
                                    <li>Must have high EQ and leadership skills</li>
                                    <li>The CEO will be in charge of leading company operations, financial and organizational management, and overall growth strategy and milestones (e.g. Affiliates development, customer acquisition and revenue).</li>
                                    <li>The CEO will own go-to-market and overall strategy and vision for E-CTO along with the co-founders and leadership team.</li>
                                    <li>The CEO will work closely with the founders to ensure the company is delivering on critical milestones for the series of  funding rounds</li>
                                    <li>Secure and negotiate strategic partnerships that will shorten the sales cycle and help accelerate the growth of the company.</li>
                                    <li>Ensure E-CTO reaches affiliates- Customers fit and focus on getting referenceable customers, scale the business and own sales and marketing.</li>
                                    <li>Develop fundraising strategy and develop investor relationships.</li>
                                </ul>
                            </p>
                        </div>
                        <div className="pt-12">
                            <h5>CMO - The Chief Marketing Officer at E-CTO.com</h5>

                            <p className="font-size-4">E-CTO.com vison is to build a network of global research institutions, acclaimed University laboratories, PhD and post-doctoral research professionals in advanced and niche domains, select software houses and Silicon Valley tech startups.   E-CTO network will synergize and deliver end to end solutions for large corporations and daring entrepreneurs with dreams to build iconic companies with complete techpower to accelerate their ideas to markets</p>

                            <p className="font-size-4">The Chief Marketing Officer at E-CTO.com blends marketing strategy and vision with demonstrated leadership to help elevate E-CTO’s presence on a global scale and support the strategic growth. As a key member of the executive leadership team, this role will report directly into the CEO and is responsible for the Company’s core marketing and communications strategy with a focus on further strengthening the company’s awareness, perception, and reputation to drive growth. We’re looking for a dynamic and creative CMO with a proven track record of building world-class pioneering technology services  that will lead our team as we continue to expand, with a customer-first mindset at every touchpoint. This role will drive the vision, strategy and execution for all elements of an integrated end-to-end marketing function, including media, brand, product and solution marketing, content and thought leadership, campaigns, demand generation, digital marketing, events, public relations and strategic communications, analyst and advisor relations and more.</p>

                            <p className="font-size-4">This person must demonstrate deep industry and market knowledge in the technology and R&D space with the ability to drive high impact marketing and communications strategies that drive advocacy and loyalty for the E-CTO brand.  Specifically, the individual should have expertise in  building a first of the type service,  and possess  experience in leading a team in developing strategies for alliance marketing, PR, and digital/social media by applying out-of-the-box thinking. You will have a unique opportunity to shape E-CTO’s launch while at the same time leveraging opportunities to develop a go-to-market strategy for select end-to-end solutions in partnership with our affiliate partners.,.</p>

                            <h6>What You’ll Do & The Impact You’ll Make:</h6>
                            <p className="font-size-4">
                                <ul>
                                    <li>Own the strategic thinking, development, ideation and execution of best-in-class integrated marketing strategies through various channels, including website, social media, industry marketing, tradeshows, events, PR/AR, campaigns, and more</li>
                                    <li>Lead evolution of E-CTO’s brand architecture, messaging, positioning, and overall narrative to ensure consistent communication of the E-CTO brand both internally and externally through all key brand touchpoints</li>
                                    <li>As a growth driver, you are responsible for developing, executing and optimizing campaigns for our four core solution areas in partnership with the affiliates  and alliances</li>
                                    <li>Oversee the day-to-day activities of the marketing team, including marketing content and collateral, demand generation, and in-house design</li>
                                    <li>Build global marketing team at scale through data-driven insights and results</li>
                                    <li>Drive strategy for industry and media exposure including press releases, award submissions, and speaking engagements</li>
                                    <li>Develop and manage comprehensive omni-channel marketing plans/budgets to meet revenue and brand health goals</li>
                                    <li>Evangelize and champion the E-CTO brand and train the organization to convey consistent brand image externally Measure the performance of all marketing team activities and campaigns, assess ROI against KPIs, and refine activities & strategies to achieve them</li>
                                </ul>
                            </p>

                            <p className="font-size-4">
                                <h6>What We’d Like To See</h6>
                                <ul>
                                    <li>Experience driving strategy for a Global organization with brand positioning that can vary by region</li>
                                    <li>Experience in developing outcome based strategies with confidence in data-led marketing. An ability to present information based on analytic</li>
                                    <li>Visionary with passion and drive –but not afraid to get your hands dirty. No task is too small. Ability to think on your feet, lead your team and change course quickly while doing multiple things at once</li>
                                    <li>Set clear priorities with the Executive Team and rebalance based upon market direction and performance.</li>
                                    <li>Intrapreneurial Spirit - Willingness to challenge the status quo and try new things. We’re a start-up at heart</li>
                                    <li>Bachelor’s degree or equivalent work experience in related field (ex. Marketing, Communications, Advertising, Business). Master’s degree in marketing or business a plus</li>
                                    <li>Prior experience in management consulting and/or digital agency environment strongly preferred</li>
                                    <li>Equivalent of 10+ years’ experience managing and mentoring a team</li>
                                    <li>Salesforce and Pardot experience a plus</li>
                                    <li>Proven track record working with teams to develop strong case studies, yielding successful award submissions, analyst mentions and PR is an added bonus</li>
                                    <li>Ability to manage budget across working media and vendors</li>
                                </ul>
                            </p>
                        </div>

                        <div className="pt-12">
                            <h5>Vice president – Engineering and Advanced technologies</h5>
                            <p className="font-size-4">
                                Reporting to the CTO, our VP, Engineering/Technology will lead our internal technology team, in a domain overseeing current and future technology trends, adoption and integration with customer product and solutions portfolios. The VP engineering will build and manage a growing team of in-house and affiliate developers collaborating on 3-5 ongoing and future projects that are, horizon next, generate new IPs and make customer organizations technology capabilities future ready.
                            </p>
                            <p className="font-size-4">
                                With considerable autonomy, and opportunity for creative vision, the VP Engineering/Technology will collaborate with colleagues and customers alike, to define the product roadmap, improve the methods in use, enhance our technology stack, and accelerate product development.

                                The best candidates will be product-centric with significant experience working on, working with, and leading teams of developers across diverse platforms, frameworks, and environments.
                            </p>
                            <p className="font-size-4">
                                <h6>Primary Duties</h6>
                                <ul>
                                    <li>Develop long term product vision and “how to get there” in defined technologies</li>
                                    <li>Build and lead teams of developers and associated managers to deliver successful projects for customers in collaboration with affiliates and inhouse engineers.</li>
                                    <li>Lead IT team and manage tech partners</li>
                                    <li>Interact with everyone from customers, to colleagues and your peers in executive leadership to determine product needs, and operational processes</li>
                                    <li>Develop budgets and manage to costs, with a focus on ROI</li>
                                    <li>Prioritize security, user experience and product performance in development</li>
                                    <li>Ensure E-CTO is building and deploying best in class products built with the latest methodologies</li>
                                    <li>Demonstrate internal and external leadership and communication and thought leadership relative to E-CTO’s products</li>
                                    <li>Consistently deliver progress on development projects with consistent, methodical merges, documentation and feedback loops for continuous improvement</li>
                                </ul>
                            </p>

                        </div>
                        <div className="pt-12 pb-12">
                            <h5>EEO Statement</h5>
                            <p className="font-size-4">E-CTO, as an Equal Opportunity Employer and considers applicants for all positions without regard to race, color, religion or belief, sex, age, national origin, citizenship status, marital status, military/veteran status, genetic information, sexual orientation, gender identity, physical or mental disability or any other characteristic protected by applicable laws. We are committed to creating a dynamic work environment that values diversity and inclusion, respect and integrity, customer focus, and innovation.</p>
                        </div>

                        <div classNmae="mt-12">
                            <p className="font-size-4"><b>Contact us at careers@e-cto.com</b></p>
                        </div>
                    </div>
                </section>
            </PageWrapper>
        </>
    );
};
export default Careers;
